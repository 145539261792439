<template>
  <div class="show-exam exam-base webkit-scrollbar" v-if="dialogVisible">
    <div class="show-main data-base">
      <p class="title">
        选择快问快答
        <i class="vh-icon vh-icon-close cursor-pointer" @click="dialogVisible = false"></i>
      </p>
      <div class="data-search" v-show="!noExamData">
        <vh-input
          class="search-dialog-tag"
          v-clearEmoij
          round
          v-model="keywordIpt"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="getExamList(false)"
          style="width: 180px"
          @clear="getExamList"
        >
          <i
            slot="prefix"
            class="vh-icon-search vh-input__icon"
            style="cursor: pointer; line-height: 36px"
            @click="getExamList(false)"
          ></i>
        </vh-input>
      </div>
      <div class="data-base-list" v-show="!noExamData">
        <vh-table
          :data="examList"
          ref="selectExamTable"
          style="width: 100%"
          height="320px"
          class="scrollbar no-border"
          @selection-change="handleSelectionChange"
          @select-all="checkAllExam"
          v-loadMore="moreLoadData"
        >
          <vh-empty
            slot="empty"
            :image="searchNoData"
            description="暂未搜索到您想要的内容"
          ></vh-empty>

          <vh-table-column type="selection" width="55"></vh-table-column>
          <vh-table-column label="名称">
            <template slot-scope="scope">
              <span class="mediaName" :title="scope.row.title">
                {{ scope.row.title }}
              </span>
            </template>
          </vh-table-column>
          <vh-table-column prop="total_score" label="总分" width="80"></vh-table-column>
          <vh-table-column prop="questions_count" label="题数" width="80"></vh-table-column>
          <vh-table-column label="限时" width="105">
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.limit_time_switch > 0
                    ? limitTime(
                        scope.row.limit_time * (scope.row.limit_time_type == 1 ? 1000 : 60 * 1000)
                      )
                    : '无'
                }}
              </span>
            </template>
          </vh-table-column>
          <vh-table-column width="65" label="操作">
            <template slot-scope="scope">
              <span class="show-hover" style="cursor: pointer" @click="preview(scope.row)">
                预览
              </span>
            </template>
          </vh-table-column>
        </vh-table>
      </div>
      <div class="no-exam" v-show="noExamData">
        <vh-empty :image="searchNoData" description="您还没有快问快答，快来创建吧!">
          <vh-button
            type="primary"
            class="create-button"
            round
            @click="addExam"
            v-preventReClick
            v-if="!isLotterySelect && !isEmbed"
          >
            创建
          </vh-button>
        </vh-empty>
      </div>
      <div v-show="!noExamData" slot="footer" class="dialog-footer">
        <p class="text">
          已选择
          <span class="color_red">{{ checkList.length }}</span>
          个
          <span v-if="!isLotterySelect">（每次最多选择20个）</span>
        </p>
        <div>
          <vh-button plain round type="info" size="medium" @click.prevent.stop="handleCloseVisiton">
            取 消
          </vh-button>
          <vh-button
            round
            size="medium"
            type="primary"
            @click.prevent.stop="choseSureExam"
            :disabled="!checkList.length"
            v-preventReClick
          >
            确 定
          </vh-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { searchNoData } from '@/utils/ossImgConfig';
  import examServer from '@/utils/examServer';
  import { isEmbed } from '../../../../utils/utils';
  export default {
    name: 'materialExamSelect',
    props: {
      isLotterySelect: {
        type: Boolean,
        default: false
      },
      checkedExamList: {
        type: Array,
        default: () => {}
      }
    },
    data() {
      return {
        isEmbed: isEmbed(),
        vm: null,
        total: 0,
        dialogVisible: false,
        loading: false,
        checkList: [],
        examList: [],
        isCheckAll: false,
        keywordIpt: '',
        examIdList: [],
        queryParams: {
          limit: 10,
          keyword: '',
          pageNum: 1
        },
        searchNoData
      };
    },
    computed: {
      // 暂未创建列表
      noExamData() {
        return this.queryParams.keyword === '' && this.total === 0;
      }
    },
    methods: {
      limitTime(val) {
        const durationObj = dayjs.duration(val);
        const hours = Math.floor(durationObj.asHours());
        const minutes = Math.floor(durationObj.asMinutes()) % 60;
        const seconds = Math.floor(durationObj.asSeconds()) % 60;

        let formattedTime = '';
        if (hours > 0) {
          formattedTime += `${hours}:`;
        }
        formattedTime += `${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
      open() {
        this.dialogVisible = true;
        this.keywordIpt = '';
        this.isCheckAll = false;
        this.examList = [];
        this.checkList = []; // 清空选中列表
        if (this.isLotterySelect) {
          this.examIdList =
            (this.checkedExamList.length && this.checkedExamList.map(item => Number(item.id))) ||
            [];
        }

        this.getExamList();
      },
      getExamList(clear = true) {
        this.queryParams.pageNum = 1;
        if (clear) {
          this.keywordIpt = '';
        }
        this.examList = [];
        this.queryExamList();
      },
      queryExamList() {
        const keywords = (this.queryParams.keyword = this.keywordIpt);
        const params = {
          limit: this.queryParams.limit,
          pos: (this.queryParams.pageNum - 1) * this.queryParams.limit,
          keywords,
          sort_field: 'paper_edit_time'
        };
        if (this.isLotterySelect) {
          params.source_id = this.$route.params.str; // 活动id
          params.source_type = 1;
          params.exclude_ids = (this.examIdList.length && this.examIdList.join(',')) || '';
        }
        examServer.getExamList(params).then(res => {
          res.data.list.map(item => {
            this.examList.push(item);
          });
          this.total = res.data.total;
          if (this.isCheckAll) {
            this.$refs.selectExamTable.toggleAllSelection();
          }
          // if (this.total && this.isLotterySelect) {
          //   this.checkList = JSON.parse(JSON.stringify(this.checkedExamList || []));
          //   this.selectedDefaultList(this.checkedExamList);
          // }
          this.firstLoad = true;
        });
      },
      selectedDefaultList(list) {
        this.$nextTick(() => {
          if (list.length) {
            let selectedList = [];
            selectedList = list.map(item => {
              return item.id;
            });
            // 如果返回的列表已经选过，就默认选中
            this.examList.forEach(item => {
              if (selectedList.includes(item.id)) {
                this.$nextTick(() => {
                  this.$refs.selectExamTable.toggleRowSelection(item, true);
                });
              }
            });
          }
        });
      },
      moreLoadData() {
        if (this.examList.length >= this.total) return false;
        this.queryParams.pageNum++;
        this.queryExamList();
      },
      //文案提示问题
      messageInfo(msg, type) {
        if (this.vm) {
          this.vm.close();
        }
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: msg,
          type: type,
          customClass: 'zdy-info-box'
        });
      },
      handleClose(done) {
        this.pageInfo.pageNum = 1;
        this.pageInfo.pos = 0;
        done();
      },
      handleCloseVisiton() {
        this.dialogVisible = false;
      },
      handleBtnClick(val) {
        let methodsCombin = this.$options.methods;
        methodsCombin[val.type](this, val);
      },
      // 选择资料库中的快问快答
      choseSureExam() {
        if (this.isLotterySelect) {
          if (this.examIdList.length + this.checkList.length > 100) {
            this.$vhMessage({
              message: `最多添加100个快问快答`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            return;
          }
          this.$emit('checkExam', this.checkList);
          this.handleCloseVisiton();
        } else {
          this.selectExamInfo();
        }
      },
      selectExamInfo() {
        if (this.checkList.length > 20) {
          this.messageInfo('每次只能添加20个快问快答', 'error');
          return;
        }
        const checkList = this.checkList.map(item => item.id);
        let params = {
          source_type: 1,
          ids: checkList.join(','),
          source_id: this.$route.params.str // 活动ID
        };
        examServer
          .selectExamFromUser(params)
          .then(() => {
            this.messageInfo('添加成功', 'success');
            this.checkList = []; // 清空选中列表
            this.dialogVisible = false;
            this.$emit('added');
          })
          .catch(() => {
            this.messageInfo('添加失败', 'error');
          });
      },
      addExam() {
        this.$router.push({
          path: '/interactive/addExam'
        });
      },
      // 预览
      preview(rows) {
        this.$emit('prev', rows);
      },
      checkAllExam(selection) {
        // 全选
        this.isCheckAll = selection && selection.length > 0;
        this.checkList = selection;
        // this.checkList = selection.map(item => item.id);
      },
      // 选中
      handleSelectionChange(val) {
        this.checkList = val;
        // this.checkList = val.map(item => item.id);
      },
      currentChangeHandler(page) {
        this.queryParams.pageNum = page;
        this.queryExamList();
      }
    }
  };
</script>
<style lang="less">
  .search-dialog-tag {
    .vh-input__icon {
      line-height: 36px;
    }
    .vh-input__inner {
      border-radius: 20px;
      height: 36px;
      padding-right: 30px !important;
      border-radius: 18px;
    }
    .vh-input__prefix {
      cursor: pointer;
    }
  }
  .data-base {
    position: relative;
    z-index: 1000;
    .title {
      padding: 32px;
      font-size: 20px;
      .cursor-pointer {
        color: #8c8c8c;
        cursor: pointer;
        &:hover {
          color: #595959;
        }
      }
      i {
        float: right;
      }
    }
    .data-search {
      padding-left: 32px;
    }
  }

  .data-base-list {
    width: 100%;
    margin: 16px 0 20px 0;
    padding: 0 32px;
    .mediaName {
      font-size: 14px;
      color: #1a1a1a;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .vh-table th {
      background-color: #f7f7f7;
    }
    .show-hover {
      &:hover {
        color: #fb3a32;
      }
    }
  }
  .text {
    font-size: 14px;
    .color_red {
      color: #fb3a32;
      padding: 0 5px;
    }
    .vh-table th {
      background: #f7f7f7;
      padding: 15px 0;
    }
    .vh-table td {
      padding: 15px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      cursor: pointer;
    }
  }
  .show-exam {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.3);
    .show-main {
      position: absolute;
      top: 48%;
      left: 50%;
      background: #fff;
      transform: translate(-50%, -50%);
      width: 800px;
      // padding: 24px 32px;
      padding-bottom: 30px;
      border-radius: 4px;
      .exam_main {
        max-height: 550px;
        position: relative;
      }
      .close-btn {
        z-index: 100;
        position: absolute;
        top: 16px;
        right: 16px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        padding: 5px;
        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .submit-footer {
        text-align: center;
        padding-top: 20px;
      }
    }
    .data-base {
      width: 800px;
    }
    .vh-table::before {
      height: 0;
    }
  }
  .show-exam .dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    // position: absolute;
    // bottom: 25px;
    // right: 30px;
  }
  .no-exam {
    height: 360px;
  }
  .cursor-pointer {
    color: #8c8c8c;
    cursor: pointer;
    &:hover {
      color: #595959;
    }
  }
</style>
